import * as React from "react";
import Layout from "../components/layout/layout";
import { css } from "@emotion/react";
import { ImageBackground } from "../components/styledComponents";
import Image from "../components/image";
import { motion } from "framer-motion";
import { useEffect } from "react";

const IndexPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <Layout>
      <ImageBackground
        tag="main"
        fluid={[
          `linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%)`,
          Image().indexBg,
        ]}
        css={css`
          width: 100%;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Brandon Grotesque", sans-serif;
          color: white;
          margin-top: -47px;

          h1 {
            font-size: 60px;
            margin: 0;
            color: white;
          }

          p {
            margin: 0;
            text-align: center;
            color: white;
          }

          @media screen and (min-width: 1024px) {
            h1 {
              font-size: 120px;
            }
          }
        `}
      >
        <title>Home</title>

        <div className="main-content">
          <h1>
            <motion.div
              animate={{ rotateY: [0, -180, -180, -360] }}
              transition={{
                ease: "easeIn",
                duration: 8,
                repeat: Infinity,
                repeatDelay: 6,
              }}
              style={{ display: "inline-block", margin: "0 10px" }}
            >
              N
            </motion.div>
            <motion.div
              animate={{ rotateY: [0, -180, -180, -360] }}
              transition={{
                ease: "easeIn",
                duration: 8,
                repeat: Infinity,
                repeatDelay: 6,
                delay: 1.5,
              }}
              style={{ display: "inline-block", margin: "0 10px" }}
            >
              O
            </motion.div>
            <motion.div
              animate={{ rotateY: [0, -180, -180, -360] }}
              transition={{
                ease: "easeIn",
                duration: 8,
                repeat: Infinity,
                repeatDelay: 6,
                delay: 2.5,
              }}
              style={{ display: "inline-block", margin: "0 10px" }}
            >
              M
            </motion.div>
            <motion.div
              animate={{ rotateY: [0, -180, -180, -360] }}
              transition={{
                ease: "easeIn",
                duration: 8,
                repeat: Infinity,
                repeatDelay: 6,
                delay: 3.5,
              }}
              style={{ display: "inline-block", margin: "0 10px" }}
            >
              A
            </motion.div>
            <motion.div
              animate={{ rotateY: [0, -180, -180, -360] }}
              transition={{
                ease: "easeIn",
                duration: 8,
                repeat: Infinity,
                repeatDelay: 6,
                delay: 4.5,
              }}
              style={{ display: "inline-block", margin: "0 10px" }}
            >
              D
            </motion.div>
          </h1>
          <p>FOOD • CRAFT • TRAVEL • HEALTH</p>
        </div>
      </ImageBackground>
    </Layout>
  );
};

export default IndexPage;
